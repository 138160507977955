import React from "react";
import { PageBody } from "app/components/RouteComponents";
import { Helmet } from "react-helmet-async";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import { useGetNotificationPreferencesQuery } from "services/graphql";
import {
  useEmailNotificationsFlag,
  usePushNotificationsFlag,
} from "hooks/useFeatureFlags";
import { AccountBodyWrapper } from "../AccountBodyWrapper";
import { EmailNotificationSettings } from "./EmailNotificationSettings";
import { PushNotificationSettings } from "./PushNotificationSettings";

function Notifications() {
  const { data } = useGetNotificationPreferencesQuery();
  const notificationPreferences = data?.me?.notificationPreferences;
  const isPushNotificationFeatureEnabled = usePushNotificationsFlag();
  const isEmailNotificationFeatureEnabled = useEmailNotificationsFlag();

  return (
    <AccountBodyWrapper>
      <Helmet title="Notifications | Online Dance Classes and Tutorials" />
      <PageBody>
        <Flex flexDirection="column" textAlign="center" my={4}>
          <H2>Manage Notifications</H2>
        </Flex>
        <Flex justifyContent="center">
          <Flex
            flexDirection="column"
            gap="32px"
            width={{ _: "100%", md: "60%" }}
          >
            {isPushNotificationFeatureEnabled && (
              <PushNotificationSettings
                notificationPreferences={notificationPreferences}
              />
            )}
            {isEmailNotificationFeatureEnabled && (
              <EmailNotificationSettings
                notificationPreferences={notificationPreferences}
              />
            )}
          </Flex>
        </Flex>
      </PageBody>
    </AccountBodyWrapper>
  );
}

export default Notifications;
